import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'periodTranslate',
    pure: true
})
export class PeriodTranslatePipe implements PipeTransform {
    transform(value: string): string {
        let path ='dashboard.createNewJobOffer'
        switch (value) {
            case 'hour':
                return `${path}.salaryPerriodH`
            case 'day':
                return `${path}.salaryPerriodD`
            case 'mounth':
                return `${path}.salaryPerriodM`
            default:
                return `${path}.salaryPerriodM`
        }
    }
}
