import { CdkStepper } from '@angular/cdk/stepper';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationToggleService } from 'src/app/shared/services/navigation-toggle.service';
import { StepperService } from '../stepper.service';

import { AllCVOptionsFromDB } from 'src/app/shared/models/cv-options.model';
import { TranslateService } from '@ngx-translate/core';
import { FinalLongCvModel, UserAttachments, UserFromBE,NewRegistrationPersonalInfo,
  NewRegistrationHighSchool,
  NewRegistrationAttachment,
  NewRegistrationUniversity,
  NewRegistrationCourse,
  NewRegistrationProject,
  NewRegistrationLanguage,
  NewRegistrationWorkExperience,
  NewRegistrationSkills,
  Language,
  Attachment, } from '@talentway/shared'
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AttachmentService } from 'src/app/shared/services/attachment.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-stepper-form',
  templateUrl: './stepper-form.component.html',
  styleUrls: ['./stepper-form.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class StepperFormComponent implements OnInit {
  date = new FormControl(moment());
  allCVOptionsFromDB!: AllCVOptionsFromDB;
  filteredGender?: Observable<string[]>;
  filteredLang?: Observable<string[]>;
  filteredSkills?: Observable<string[]>;
  finallFormData!: FinalLongCvModel;

  newRegistrationFormPersonalInfo!: FormGroup;
  newRegistrationPersonalInfo!: NewRegistrationPersonalInfo;
  educationTitles = ['Bc', 'Mgr', 'Ing'];

  newRegistrationFormHighSchool!: FormGroup;
  newRegistrationHighSchool: NewRegistrationHighSchool[] = [];
  tempnewRegistrationHighSchool = false;
  tempEditnewRegistrationHighSchool = false;

  newRegistrationFormUniversity!: FormGroup;
  newRegistrationUniversity: NewRegistrationUniversity[] = [];
  tempnewRegistrationUniversity = false;
  tempEditnewRegistrationUniversity = false;

  newRegistrationFormCourse!: FormGroup;
  newRegistrationCourse: NewRegistrationCourse[] = [];
  tempnewRegistrationCourse = false;
  tempEditnewRegistrationCourse = false;

  newRegistrationFormProject!: FormGroup;
  newRegistrationProject: NewRegistrationProject[] = [];
  tempnewRegistrationProject = false;
  tempEditnewRegistrationProject = false;

  newRegistrationFormLanguages!: FormGroup;
  newRegistrationLanguages: NewRegistrationLanguage[] = [];
  tempnewRegistrationLanguages = false;
  tempEditnewRegistrationLanguages = false;

  newRegistrationFormWorkExperience!: FormGroup;
  newRegistrationWorkExperience: NewRegistrationWorkExperience[] = [];
  tempnewRegistrationWorkExperience = false;
  tempEditnewRegistrationWorkExperience = false;

  newRegistrationFormSkills!: FormGroup;
  newRegistrationSkills: NewRegistrationSkills[] = [];
  tempnewRegistrationSkills = false;
  tempEditnewRegistrationSkills = false;

  newRegistrationCandidateAttachments: NewRegistrationAttachment[] = [];
  fileName = '';
  toFile!: any;
  blobToBE!: any;

  togleNav = false;
  subscription!: Subscription;
  user: UserFromBE;
  cv: FinalLongCvModel = null;
  formLoaded = false;
  cancel: string;
  msg: string;

  customValidators = {
    telFormControl: new FormControl('', [
      Validators.required,
      Validators.pattern('[- +()0-9]+'),
    ]
    ),
    emailFormControl: new FormControl('', [
      Validators.required,
      Validators.email,
    ]
    ),
    endDateFormControl: [
      Validators.maxLength(250)
    ]
  };

  @ViewChild('cdkStepper', { static: false }) cdkStepper!: CdkStepper;

  constructor(
    private formBuilder: FormBuilder,
    private stepperService: StepperService,
    private navService: NavigationToggleService,
    private translate: TranslateService,
    private attachmentService: AttachmentService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getCVfromDB().then(() => this.load())
    this.getCVOptionsFromDB().then(() => {
      this.subscription = this.navService.currentNavToggle.subscribe(navToggle => { this.togleNav = navToggle; });
      });
    }

  load(): void {
    this.buildRegistarionFormPersonalInfo();
    this.buildRegistrationFormHighSchoolEducation();
    this.buildRegistrationFormUniversity();
    this.buildRegistrationFormProject();
    this.buildRegistarionFormLanguages();
    this.buildRegistarionFormWorkExperience();
    this.buildRegistarionFormSkills();

    if(this.cv !== null){
      this.saveNewRegistrationPersonalInfo(this.newRegistrationFormPersonalInfo)
      this.cv.highSchoolEducations.forEach(school => this.addExistingHighSchool(school))
      this.cv.universityEducations.forEach(school => this.addExistingUniversity(school))
      this.cv.projects.forEach(project => this.addExistingProject(project))
      this.cv.languages.forEach(language => this.addExistingLanguage(language))
      this.cv.workExperiences.forEach(work => this.addExistingWorkExperience(work))
      this.cv.skills.forEach(skill => this.addExistingSkill(skill))
    }

    this.formLoaded = true;
  }


  // tslint:disable-next-line:typedef
  getCVOptionsFromDB() {
    return new Promise((resolve, reject) => {
      this.stepperService.getCVOptions().subscribe(items => {
        this.allCVOptionsFromDB = items.body as any;
        resolve(items);
        return items;
      });
    });
  }

  getCVfromDB() {
    return new Promise((resolve, reject) => {
      if(this.user !== null && this.user.longcv !== null){

        this.stepperService.getCV(this.user.longcv.id).subscribe(items => {
          this.cv = items.body;
          resolve(items);
          return items;
        });
      }else{
        resolve(null);
        return null;
      }
    });
  }

  toggleNavigation(): void {
    this.togleNav = !this.togleNav;
    this.navService.changeNavToggle(this.togleNav);
  }


  buildRegistarionFormPersonalInfo(): void {
    this.newRegistrationFormPersonalInfo = this.formBuilder.group({
      gender: new FormControl(this.cv !== null ? this.cv.gender : ''),
      name: new FormControl(this.user !== null ? this.user.firstName : '', Validators.required),
      surname: new FormControl(this.user !== null ? this.user.lastName : '', Validators.required),
      showEducationTitle: new FormControl(false),
      title: new FormControl(this.cv !== null ? this.cv.title : ''),
      titleAfterName: new FormControl(this.cv !== null ? this.cv.titleAfterName : ''),
      birthDate: new FormControl(this.cv !== null ? this.cv.birthDate : '', Validators.required),
      email: new FormControl(this.user !== null ? this.user.email : '', [Validators.required, Validators.email]),
      telephone: new FormControl(this.cv !== null ? this.cv.telephone : '', Validators.pattern('[- +()0-9]+')),
      streetName: new FormControl(this.cv !== null ? this.cv.streetName : ''),
      streetNumber: new FormControl(this.cv !== null ? this.cv.streetNumber : ''),
      zip: new FormControl(this.cv !== null ? this.cv.zip : ''),
      city: new FormControl(this.cv !== null ? this.cv.city : ''),
      country: new FormControl(this.cv !== null ? this.cv.country : '', Validators.required),
    });


    this.filteredGender = this.newRegistrationFormPersonalInfo.get('gender')?.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGender(value)),
      );
  }

  private _filterGender(value: string): string[] {
    let filterValue = '';
    if (value) {
      filterValue = value.toLowerCase();
    }
    // tslint:disable-next-line:no-non-null-assertion
    return this.allCVOptionsFromDB.genders!.filter(option => option.toLowerCase().includes(filterValue));
  }

  setDatePersonalInfo(dateObject: MatDatepickerInputEvent<Date>): void {
    if (this.newRegistrationFormPersonalInfo.controls.birthDate.status !== 'INVALID') {
      let date = new Date(dateObject.value)
      const correctMonth = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1)
        : (date.getMonth() + 1);
      const correctDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      this.newRegistrationFormPersonalInfo.controls.birthDate.setValue(date.getFullYear() +
        '-' + correctMonth + '-' + correctDay);
    }
  }


  saveNewRegistrationPersonalInfo(form: any): void {
    const newPersonalInfo = new NewRegistrationPersonalInfo();
    newPersonalInfo.gender = form.controls.gender.value;
    newPersonalInfo.name = form.controls.name.value;
    newPersonalInfo.surname = form.controls.surname.value;
    newPersonalInfo.title = form.controls.title.value;
    newPersonalInfo.titleAfterName = form.controls.titleAfterName.value;
    newPersonalInfo.birthDate = form.controls.birthDate.value;
    newPersonalInfo.email = form.controls.email.value;
    newPersonalInfo.telephone = form.controls.telephone.value;
    newPersonalInfo.streetName = form.controls.streetName.value;
    newPersonalInfo.streetNumber = form.controls.streetNumber.value;
    newPersonalInfo.zip = +form.controls.zip.value;
    newPersonalInfo.city = form.controls.city.value;
    newPersonalInfo.country = form.controls.country.value;

    this.newRegistrationPersonalInfo = newPersonalInfo;
    console.log(this.newRegistrationPersonalInfo)
  }

  onPersonalInfoFormValid(): void {
    const nextBtn = document.querySelector('[data-first-next]');
    nextBtn?.classList.add('valid-submit-button');
  }


  // TODO High School Education

  buildRegistrationFormHighSchoolEducation(): void {

    const file = this.formBuilder.group({
      url: new FormControl(''),
      fileType: new FormControl(''),
      type: new FormControl(''),
      fileName: new FormControl('')
    });

    this.newRegistrationFormHighSchool = this.formBuilder.group({
      addNewHighSchool: new FormControl(false),
      highSchoolName: new FormControl('', Validators.required),
      fieldOfStudy: new FormControl(''),
      city: new FormControl(''),
      country: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      attachmentID: new FormControl(0),
      candidateAttachment: file,
      edit: new FormControl(false),
    });

    this.newRegistrationFormHighSchool.disable();
  }


  setStartDateHighSchool(dateObject: MatDatepickerInputEvent<Date>): void {
    if (this.newRegistrationFormHighSchool.controls.startDate.status !== 'INVALID') {
      let date = new Date(dateObject.value)
      const correctMonth = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1)
        : (date.getMonth() + 1);
      const correctDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      this.newRegistrationFormHighSchool.controls.startDate.setValue(date.getFullYear() +
        '-' + correctMonth + '-' + correctDay);
    }
  }

  setEndDateHighSchool(dateObject: MatDatepickerInputEvent<Date>): void {
    if (this.newRegistrationFormHighSchool.controls.endDate.status !== 'INVALID') {
      let date = new Date(dateObject.value)
      const correctMonth = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1)
        : (date.getMonth() + 1);
      const correctDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      this.newRegistrationFormHighSchool.controls.endDate.setValue(date.getFullYear() + '-' + correctMonth + '-' + correctDay);
    }
  }



  createNewRegistrationHighSchool(form: any): void {
    if (!this.tempEditnewRegistrationHighSchool) {
      form.reset();
      this.newRegistrationFormHighSchool.enable();
      this.tempnewRegistrationHighSchool = true;
      const addNewHighSchool = !this.newRegistrationFormHighSchool.controls.addNewHighSchool.value;

      this.newRegistrationFormHighSchool.controls.addNewHighSchool.setValue(addNewHighSchool);
    }
  }

  addExistingHighSchool(school: NewRegistrationHighSchool){
    const newAtt = new NewRegistrationAttachment();
    newAtt.url = school.candidateAttachment.url;
    newAtt.fileType = school.candidateAttachment.fileType;
    newAtt.type = school.candidateAttachment.type;
    newAtt.fileName = school.candidateAttachment.fileName;

    const highSchool = new NewRegistrationHighSchool();
    highSchool.id = school.id;
    highSchool.highSchoolName = school.highSchoolName;
    highSchool.fieldOfStudy = school.fieldOfStudy;
    highSchool.city = school.city;
    highSchool.country = school.country;
    highSchool.startDate = new Date(school.startDate);
    highSchool.endDate = new Date(school.endDate);
    highSchool.candidateAttachment = newAtt;
    highSchool.attachmentID = school.attachmentID;
    highSchool.edit = false;
    highSchool.personalDataID = school.personalDataID;
    this.newRegistrationHighSchool.push(highSchool);

  }

  saveNewHighSchool(form: any): void {
    this.newRegistrationFormHighSchool.markAllAsTouched();
    if(!this.newRegistrationFormHighSchool.valid){
      return
    }
    const newAtt = new NewRegistrationAttachment();
    newAtt.url = form.controls.candidateAttachment.controls.url.value;
    newAtt.fileType = form.controls.candidateAttachment.controls.fileType.value;
    newAtt.type = form.controls.candidateAttachment.controls.type.value;
    newAtt.fileName = form.controls.candidateAttachment.controls.fileName.value;

    const highSchool = new NewRegistrationHighSchool();
    highSchool.highSchoolName = form.controls.highSchoolName.value;
    highSchool.fieldOfStudy = form.controls.fieldOfStudy.value;
    highSchool.city = form.controls.city.value;
    highSchool.country = form.controls.country.value;
    highSchool.startDate = new Date(form.controls.startDate.value);
    highSchool.endDate = new Date(form.controls.endDate.value);
    highSchool.candidateAttachment = newAtt;
    highSchool.attachmentID = form.controls.attachmentID.value;
    highSchool.edit = false;
    this.newRegistrationHighSchool.push(highSchool);
    this.newRegistrationFormHighSchool.controls.addNewHighSchool.setValue(false);
    this.tempnewRegistrationHighSchool = false;
    this.newRegistrationFormHighSchool.reset();
    this.newRegistrationFormHighSchool.disable();

  }
  closeCreateNewRegistrationHighSchool(form: any): void {
    this.tempnewRegistrationHighSchool = false;
    const addNewHighSchool = !this.newRegistrationFormHighSchool.controls.addNewHighSchool.value;
    this.newRegistrationFormHighSchool.controls.addNewHighSchool.setValue(addNewHighSchool);

    form.reset();
    // tslint:disable-next-line:forin
    for (const name in form.controls) {
      form.controls[name].setErrors(null);
    }
    this.newRegistrationFormHighSchool.disable();
  }

  goToNextStepFromHighSchool(): void {
    if (this.newRegistrationFormHighSchool.disabled
      && !this.tempnewRegistrationHighSchool
      && !this.tempEditnewRegistrationHighSchool) {
      this.cdkStepper.next();
    }
  }

  editItemOfNewRegistrationHighSchool<T>(form: any, arr: Array<NewRegistrationHighSchool>, index: number): void {
    if (index > -1) {
      arr[index].edit = true;
      form.reset();
      this.newRegistrationFormHighSchool.enable();
      this.tempEditnewRegistrationHighSchool = true;
      const editNewHighSchool = !form.controls.edit.value;
      form.controls.edit.setValue(editNewHighSchool);
      form.controls.highSchoolName.setValue(arr[index].highSchoolName);
      form.controls.fieldOfStudy.setValue(arr[index].fieldOfStudy);
      form.controls.city.setValue(arr[index].city);
      form.controls.country.setValue(arr[index].country);
      form.controls.startDate.setValue(arr[index].startDate);
      form.controls.endDate.setValue(arr[index].endDate);
      form.controls.attachmentID.setValue(arr[index].attachmentID);
      form.controls.candidateAttachment.controls.url.setValue(arr[index].candidateAttachment.url);
      form.controls.candidateAttachment.controls.fileType.setValue(arr[index].candidateAttachment.fileType);
      form.controls.candidateAttachment.controls.type.setValue(arr[index].candidateAttachment.type);
      form.controls.candidateAttachment.controls.fileName.setValue(arr[index].candidateAttachment.fileName);
    }
  }

  closeEditItemOfNewRegistrationHighSchool(form: any, arr: Array<NewRegistrationHighSchool>, index: number): void {
    arr[index].edit = false;
    this.tempEditnewRegistrationHighSchool = false;
    this.newRegistrationFormHighSchool.disable();
  }

  saveEditItemOfNewRegistrationHighSchool(form: any, arr: Array<NewRegistrationHighSchool>, index: number): void {
    if (index > -1) {
      arr[index].edit = false;
      arr[index].highSchoolName = form.controls.highSchoolName.value;
      arr[index].fieldOfStudy = form.controls.fieldOfStudy.value;
      arr[index].city = form.controls.city.value;
      arr[index].country = form.controls.country.value;
      arr[index].attachmentID = form.controls.attachmentID.value;
      arr[index].startDate = new Date(form.controls.startDate.value);
      arr[index].endDate = new Date(form.controls.endDate.value);
      arr[index].candidateAttachment.url = form.controls.candidateAttachment.controls.url.value;
      arr[index].candidateAttachment.fileType = form.controls.candidateAttachment.controls.fileType.value;
      arr[index].candidateAttachment.type = form.controls.candidateAttachment.controls.type.value;
      arr[index].candidateAttachment.fileName = form.controls.candidateAttachment.controls.fileName.value;
      form.controls.edit.setValue(false);
      this.tempEditnewRegistrationHighSchool = false;
      this.newRegistrationFormHighSchool.disable();

    }
  }


  // TODO University
  buildRegistrationFormUniversity(): void {
    const file = this.formBuilder.group({
      url: new FormControl(''),
      fileType: new FormControl(''),
      type: new FormControl(''),
      fileName: new FormControl(''),
    });
    this.newRegistrationFormUniversity = this.formBuilder.group({
      addNewUniversity: new FormControl(false),
      universityName: new FormControl('', Validators.required),
      fieldOfStudy: new FormControl('', Validators.required),
      city: new FormControl(''),
      country: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      current: new FormControl(true),
      endDate: new FormControl(''),
      attachmentID: new FormControl(0),
      candidateAttachment: file,
      edit: new FormControl(false),
    });

    this.newRegistrationFormUniversity.get('current')?.valueChanges
      .subscribe(value => {
        if (!value) {
          this.newRegistrationFormUniversity.get('endDate')?.addValidators(Validators.required);
        } else {
          this.newRegistrationFormUniversity.get('endDate')?.removeValidators(Validators.required);
          this.newRegistrationFormUniversity.controls.endDate.setErrors(null);
        }
      }
      );

      this.newRegistrationFormUniversity.disable()
  }

  setStartDateUniversity(dateObject: MatDatepickerInputEvent<Date>): void {
    if (this.newRegistrationFormUniversity.controls.startDate.status !== 'INVALID') {
      let date = new Date(dateObject.value)
      const correctMonth = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1)
        : (date.getMonth() + 1);
      const correctDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      this.newRegistrationFormUniversity.controls.startDate.setValue(date.getFullYear() +
        '-' + correctMonth + '-' + correctDay);
    }
  }

  setEndDateUniversity(dateObject: MatDatepickerInputEvent<Date>): void {
    if (this.newRegistrationFormUniversity.controls.endDate.status !== 'INVALID') {
      let date = new Date(dateObject.value)
      const correctMonth = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1)
        : (date.getMonth() + 1);
      const correctDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      this.newRegistrationFormUniversity.controls.endDate.setValue(date.getFullYear() + '-' + correctMonth + '-' + correctDay);
    }
  }


  createNewRegistrationUniversity(form: any): void {
    if (!this.tempEditnewRegistrationUniversity) {
      form.reset();
      this.newRegistrationFormUniversity.enable();
      this.tempnewRegistrationUniversity = true;
      const addNewUniversity = !this.newRegistrationFormUniversity.controls.addNewUniversity.value;
      this.newRegistrationFormUniversity.controls.addNewUniversity.setValue(addNewUniversity);
      this.newRegistrationFormUniversity.controls.current.setValue(false);
    }
  }

  addExistingUniversity(uni: NewRegistrationUniversity){
    const newAtt = new NewRegistrationAttachment();
    newAtt.url = uni.candidateAttachment.url;
    newAtt.fileType = uni.candidateAttachment.fileType;
    newAtt.type = uni.candidateAttachment.type;
    newAtt.fileName = uni.candidateAttachment.fileName;

    const University = new NewRegistrationUniversity();
    University.id = uni.id;
    University.universityName = uni.universityName;
    University.fieldOfStudy = uni.fieldOfStudy;
    University.city = uni.city;
    University.country = uni.country;
    University.startDate = new Date(uni.startDate);
    University.current = uni.current;
    University.endDate = uni.endDate !== null ? new Date(uni.endDate): null;
    University.attachmentID = uni.attachmentID;
    University.candidateAttachment = newAtt;
    University.edit = false;
    University.personalDataID = uni.personalDataID;
    this.newRegistrationUniversity.push(University);
  }

  saveNewUniversity(form: any): void {
    this.newRegistrationFormUniversity.markAllAsTouched();
    if(!this.newRegistrationFormUniversity.valid){
      return
    }
    const newAtt = new NewRegistrationAttachment();
    newAtt.url = form.controls.candidateAttachment.controls.url.value;
    newAtt.fileType = form.controls.candidateAttachment.controls.fileType.value;
    newAtt.type = form.controls.candidateAttachment.controls.type.value;
    newAtt.fileName = form.controls.candidateAttachment.controls.fileName.value;

    const University = new NewRegistrationUniversity();
    University.universityName = form.controls.universityName.value;
    University.fieldOfStudy = form.controls.fieldOfStudy.value;
    University.city = form.controls.city.value;
    University.country = form.controls.country.value;
    University.startDate = new Date(form.controls.startDate.value);
    University.current = form.controls.current.value;
    University.endDate = form.controls.current.value ? null : new Date(form.controls.endDate.value);
    University.attachmentID = form.controls.attachmentID.value;
    University.candidateAttachment = newAtt;
    University.edit = false;
    this.newRegistrationUniversity.push(University);
    this.newRegistrationFormUniversity.controls.addNewUniversity.setValue(false);
    this.tempnewRegistrationUniversity = false;
    this.newRegistrationFormUniversity.reset();
    this.newRegistrationFormUniversity.disable();
  }

  closeCreateNewRegistrationUniversity(form: any): void {
    this.tempnewRegistrationUniversity = false;
    const addNewUniversity = !this.newRegistrationFormUniversity.controls.addNewUniversity.value;
    this.newRegistrationFormUniversity.controls.addNewUniversity.setValue(addNewUniversity);
    form.reset();
    // tslint:disable-next-line:forin
    for (const name in form.controls) {
      form.controls[name].setErrors(null);
    }
    this.newRegistrationFormUniversity.disable();

  }

  goToNextStepFromUniversity(): void {

    if (this.newRegistrationFormUniversity.disabled) {
      this.cdkStepper.next();
    }
  }


  editItemOfNewRegistrationUniversity<T>(form: any, arr: Array<NewRegistrationUniversity>, index: number): void {
    if (index > -1) {
      arr[index].edit = true;
      form.reset();
      this.newRegistrationFormUniversity.enable();
      this.tempEditnewRegistrationUniversity = true;
      const editNewUniversity = !form.controls.edit.value;
      form.controls.edit.setValue(editNewUniversity);
      form.controls.universityName.setValue(arr[index].universityName);
      form.controls.fieldOfStudy.setValue(arr[index].fieldOfStudy);
      form.controls.city.setValue(arr[index].city);
      form.controls.country.setValue(arr[index].country);
      form.controls.startDate.setValue(arr[index].startDate);
      form.controls.current.setValue(arr[index].current);
      form.controls.endDate.setValue(arr[index].endDate);
      form.controls.attachmentID.setValue(arr[index].attachmentID);
      form.controls.candidateAttachment.controls.url.setValue(arr[index].candidateAttachment.url);
      form.controls.candidateAttachment.controls.fileType.setValue(arr[index].candidateAttachment.fileType);
      form.controls.candidateAttachment.controls.type.setValue(arr[index].candidateAttachment.type);
      form.controls.candidateAttachment.controls.fileName.setValue(arr[index].candidateAttachment.fileName);
    }
  }

  closeEditItemOfNewRegistrationUniversity(form: any, arr: Array<NewRegistrationUniversity>, index: number): void {
    arr[index].edit = false;
    this.newRegistrationFormUniversity.reset();
    this.newRegistrationFormUniversity.disable();
    this.tempEditnewRegistrationUniversity = false;
  }

  saveEditItemOfNewRegistrationUniversity(form: any, arr: Array<NewRegistrationUniversity>, index: number): void {
    if (index > -1) {
      arr[index].edit = false;
      arr[index].universityName = form.controls.universityName.value;
      arr[index].fieldOfStudy = form.controls.fieldOfStudy.value;
      arr[index].city = form.controls.city.value;
      arr[index].country = form.controls.country.value;
      arr[index].startDate = new Date(form.controls.startDate.value);
      arr[index].current = form.controls.current.value;
      arr[index].attachmentID = form.controls.attachmentID.value;
      arr[index].endDate = new Date(form.controls.endDate.value);
      arr[index].candidateAttachment.url = form.controls.candidateAttachment.controls.url.value;
      arr[index].candidateAttachment.fileType = form.controls.candidateAttachment.controls.fileType.value;
      arr[index].candidateAttachment.type = form.controls.candidateAttachment.controls.type.value;
      arr[index].candidateAttachment.fileName = form.controls.candidateAttachment.controls.fileName.value;
      form.controls.edit.setValue(false);
      this.tempEditnewRegistrationUniversity = false;
      this.newRegistrationFormUniversity.reset();
      this.newRegistrationFormUniversity.disable();
    }
  }

  // TODO Project
  buildRegistrationFormProject(): void {
    this.newRegistrationFormProject = this.formBuilder.group({
      addNewProject: new FormControl(false),
      title: new FormControl('', Validators.required),
      position: new FormControl('', Validators.required),
      duration: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      edit: new FormControl(false),
    });
    this.newRegistrationFormProject.disable();
  }

  createNewRegistrationProject(form: any): void {
    if (!this.tempEditnewRegistrationProject) {
      this.newRegistrationFormProject.enable();
      form.reset();
      this.tempnewRegistrationProject = true;
      const addNewProject = !this.newRegistrationFormProject.controls.addNewProject.value;
      this.newRegistrationFormProject.controls.addNewProject.setValue(addNewProject);
    }
  }

  addExistingProject(project: NewRegistrationProject){
    const Project = new NewRegistrationProject();
    Project.id = project.id;
    Project.title = project.title;
    Project.position = project.position;
    Project.duration = project.duration;
    Project.country = project.country;
    Project.edit = false;
    Project.personalDataID = project.personalDataID;
    this.newRegistrationProject.push(Project);
  }

  saveNewProject(form: any): void {
    this.newRegistrationFormProject.markAllAsTouched();
    if(!this.newRegistrationFormProject.valid){
      return
    }

    const Project = new NewRegistrationProject();
    Project.title = form.controls.title.value;
    Project.position = form.controls.position.value;
    Project.duration = form.controls.duration.value;
    Project.country = form.controls.country.value;
    Project.edit = false;
    this.newRegistrationProject.push(Project);
    this.newRegistrationFormProject.controls.addNewProject.setValue(false);
    this.newRegistrationFormProject.disable();
    this.tempnewRegistrationProject = false;
  }
  closeCreateNewRegistrationProject(form: any): void {
    this.tempnewRegistrationProject = false;
    const addNewProject = !this.newRegistrationFormProject.controls.addNewProject.value;
    this.newRegistrationFormProject.controls.addNewProject.setValue(addNewProject);
    form.reset();
    // tslint:disable-next-line:forin
    for (const name in form.controls) {
      form.controls[name].setErrors(null);
    }
    this.newRegistrationFormProject.disable();
  }

  goToNextStepFromProject(): void {
    if (this.newRegistrationFormProject.disabled && !this.tempnewRegistrationProject && !this.tempEditnewRegistrationProject) {
      this.cdkStepper.next();
    }
  }

  editItemOfNewRegistrationProject<T>(form: any, arr: Array<NewRegistrationProject>, index: number): void {
    if (index > -1) {
      this.newRegistrationFormProject.enable();
      arr[index].edit = true;
      form.reset();
      this.tempEditnewRegistrationProject = true;
      const editNewProject = !form.controls.edit.value;
      form.controls.title.setValue(arr[index].title);
      form.controls.position.setValue(arr[index].position);
      form.controls.duration.setValue(arr[index].duration);
      form.controls.country.setValue(arr[index].country);
      form.controls.edit.setValue(editNewProject);
    }
  }

  closeEditItemOfNewRegistrationProject(form: any, arr: Array<NewRegistrationProject>, index: number): void {
    arr[index].edit = false;
    this.newRegistrationFormProject.disable();
    this.tempEditnewRegistrationProject = false;
  }

  saveEditItemOfNewRegistrationProject(form: any, arr: Array<NewRegistrationProject>, index: number): void {
    if (index > -1) {
      arr[index].title = form.controls.title.value;
      arr[index].position = form.controls.position.value;
      arr[index].duration = form.controls.duration.value;
      arr[index].country = form.controls.country.value;
      arr[index].edit = false;
      form.controls.edit.setValue(false);
      this.tempEditnewRegistrationProject = false;
      this.newRegistrationFormProject.disable();
  }
  }


  // TODO Languages
  buildRegistarionFormLanguages(): void {
    this.newRegistrationFormLanguages = this.formBuilder.group({
      addNewLanguage: new FormControl(false),
      language: new FormControl('', Validators.required),
      level: new FormControl('', Validators.required),
      edit: new FormControl(false),
    });
    this.filteredLang = this.newRegistrationFormLanguages.get('language')?.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterLang(value)),
      );

    this.newRegistrationFormLanguages.disable();
  }

  private _filterLang(value: string): string[] {
    let filterValue = '';
    if (value) {
      filterValue = value.toLowerCase();
    }
    // tslint:disable-next-line:no-non-null-assertion
    return this.allCVOptionsFromDB.languages!.filter(option => option.toLowerCase().includes(filterValue));
  }

  createNewRegistrationLanguage(form: any): void {
    if (!this.tempEditnewRegistrationLanguages) {
      this.newRegistrationFormLanguages.enable();
      form.reset();
      this.tempnewRegistrationLanguages = true;
      const addNewLanguage = !this.newRegistrationFormLanguages.controls.addNewLanguage.value;
      this.newRegistrationFormLanguages.controls.addNewLanguage.setValue(addNewLanguage);
    }
  }

  addExistingLanguage(language: NewRegistrationLanguage): void {
    const newLanguage = new NewRegistrationLanguage();
    newLanguage.id = language.id;
    newLanguage.language = language.language;
    newLanguage.level = language.level;
    newLanguage.edit = false;
    newLanguage.personalDataID = language.personalDataID;
    this.newRegistrationLanguages.push(newLanguage);
  }

  saveNewLanguage(form: any): void {
    this.newRegistrationFormLanguages.markAllAsTouched();
    if(!this.newRegistrationFormLanguages.valid){
      return
    }

    const newLanguage = new NewRegistrationLanguage();
    newLanguage.language = form.controls.language.value;
    newLanguage.level = form.controls.level.value;
    newLanguage.edit = false;
    this.newRegistrationLanguages.push(newLanguage);
    this.newRegistrationFormLanguages.controls.addNewLanguage.setValue(false);
    this.newRegistrationFormLanguages.disable();
    this.tempnewRegistrationLanguages = false;
  }
  closeCreatenewRegistrationLanguage(form: any): void {
    this.tempnewRegistrationLanguages = false;
    const addNewLanguage = !this.newRegistrationFormLanguages.controls.addNewLanguage.value;
    this.newRegistrationFormLanguages.controls.addNewLanguage.setValue(addNewLanguage);
    form.reset();
    // tslint:disable-next-line:forin
    for (const name in form.controls) {
      form.controls[name].setErrors(null);
    }
    this.newRegistrationFormLanguages.disable();
  }

  goToNextStepFromLanguages(): void {
    if (this.newRegistrationLanguages.length == 0){
      this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
        this.cancel = resp;
      });
      this.translate.get('stepperForm.atLeastOneLanguage', { value: '' }).subscribe((resp: string) => {
        this.msg = resp;
      });
      this.snackBar.open(this.msg, this.cancel, {
        duration: 3000,
        panelClass: ['snackbar-formater']
      });
      return
    }
    if (this.newRegistrationFormLanguages.disabled
      && !this.tempnewRegistrationLanguages
      && !this.tempEditnewRegistrationLanguages) {
      this.cdkStepper.next();
    }
  }

  editItemOfNewRegistrationLanguages<T>(form: any, arr: Array<NewRegistrationLanguage>, index: number): void {
    if (index > -1) {
      arr[index].edit = true;
      this.newRegistrationFormLanguages.enable();
      form.reset();
      this.tempEditnewRegistrationLanguages = true;
      const editNewLanguages = !form.controls.edit.value;
      form.controls.edit.setValue(editNewLanguages);
      form.controls.language.setValue(arr[index].language);
      form.controls.level.setValue(arr[index].level);
    }
  }

  closeEditItemOfNewRegistrationLanguages(form: any, arr: Array<NewRegistrationLanguage>, index: number): void {
    arr[index].edit = false;
    this.newRegistrationFormLanguages.disable();
    this.tempEditnewRegistrationLanguages = false;
  }

  saveEditItemOfNewRegistrationLanguages(form: any, arr: Array<NewRegistrationLanguage>, index: number): void {
    if (index > -1) {

      arr[index].language = form.controls.language.value;
      arr[index].level = form.controls.level.value;
      arr[index].edit = false;
      form.controls.edit.setValue(false);
      this.newRegistrationFormLanguages.disable();
      this.tempEditnewRegistrationLanguages = false;
    }
  }

  // TODO WorkExperience
  buildRegistarionFormWorkExperience(): void {
    this.newRegistrationFormWorkExperience = this.formBuilder.group({
      addNewWorkExperience: new FormControl(false),
      position: new FormControl('', Validators.required),
      employer: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      current: new FormControl(false),
      endDate: new FormControl(''),
      country: new FormControl('', Validators.required),
      edit: new FormControl(false),
    });

    this.newRegistrationFormWorkExperience.get('current')?.valueChanges
      .subscribe(value => {
        if (!value) {
          this.newRegistrationFormWorkExperience.get('endDate')?.addValidators(Validators.required);
        } else {
          this.newRegistrationFormWorkExperience.get('endDate')?.removeValidators(Validators.required);
          this.newRegistrationFormWorkExperience.controls.endDate.setErrors(null);
        }
      }
      );
      this.newRegistrationFormWorkExperience.disable();
  }

  setStartDateWorkExperience(dateObject: MatDatepickerInputEvent<Date>): void {
    let date = new Date(dateObject.value)
    if (this.newRegistrationFormWorkExperience.controls.startDate.status !== 'INVALID') {
      const correctMonth = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1)
        : (date.getMonth() + 1);
      const correctDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      this.newRegistrationFormWorkExperience.controls.startDate.setValue(date.getFullYear() +
        '-' + correctMonth + '-' + correctDay);
    }
  }

  setEndDateWorkExperience(dateObject: MatDatepickerInputEvent<Date>): void {
    let date = new Date(dateObject.value)
    if (this.newRegistrationFormWorkExperience.controls.endDate.status !== 'INVALID') {
      const correctMonth = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1)
        : (date.getMonth() + 1);
      const correctDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      this.newRegistrationFormWorkExperience.controls.endDate.setValue(date.getFullYear() +
        '-' + correctMonth + '-' + correctDay);
    }
  }


  createNewRegistrationWorkExperience(form: any): void {
    if (!this.tempEditnewRegistrationWorkExperience) {
      this.newRegistrationFormWorkExperience.enable();
      form.reset();
      this.tempnewRegistrationWorkExperience = true;
      const addNewWorkExperience = !this.newRegistrationFormWorkExperience.controls.addNewWorkExperience.value;
      this.newRegistrationFormWorkExperience.controls.addNewWorkExperience.setValue(addNewWorkExperience);
    }
  }

  addExistingWorkExperience(work: NewRegistrationWorkExperience): void {
    const newWorkExperience = new NewRegistrationWorkExperience();
    newWorkExperience.id = work.id;
    newWorkExperience.position = work.position;
    newWorkExperience.employer = work.employer;
    newWorkExperience.city = work.city;
    newWorkExperience.startDate = new Date(work.startDate);
    newWorkExperience.current = work.current;
    newWorkExperience.endDate = work.endDate !== null ? new Date(work.endDate) : null;
    newWorkExperience.country = work.country;
    newWorkExperience.edit = false;
    newWorkExperience.personalDataID = work.personalDataID;
    this.newRegistrationWorkExperience.push(newWorkExperience);
  }

  saveNewWorkExperience(form: any): void {
    this.newRegistrationFormWorkExperience.markAllAsTouched();
    if(!this.newRegistrationFormWorkExperience.valid){
      return
    }
    const newWorkExperience = new NewRegistrationWorkExperience();
    newWorkExperience.position = form.controls.position.value;
    newWorkExperience.employer = form.controls.employer.value;
    newWorkExperience.city = form.controls.city.value;
    newWorkExperience.startDate = new Date(form.controls.startDate.value);
    newWorkExperience.current = form.controls.current.value;
    newWorkExperience.endDate = new Date(form.controls.endDate.value);
    newWorkExperience.country = form.controls.country.value;
    newWorkExperience.edit = false;
    this.newRegistrationWorkExperience.push(newWorkExperience);
    this.newRegistrationFormWorkExperience.controls.addNewWorkExperience.setValue(false);
    this.newRegistrationFormWorkExperience.reset();
    this.newRegistrationFormWorkExperience.disable();
    this.tempnewRegistrationWorkExperience = false;
  }
  closeCreateNewRegistrationworkExperience(form: any): void {

    this.tempnewRegistrationWorkExperience = false;
    const addNewWorkExperience = !this.newRegistrationFormWorkExperience.controls.addNewWorkExperience.value;
    this.newRegistrationFormWorkExperience.controls.addNewWorkExperience.setValue(addNewWorkExperience);

    form.reset();
    // tslint:disable-next-line:forin
    for (const name in form.controls) {
      form.controls[name].setErrors(null);
    }
    this.newRegistrationFormWorkExperience.disable();
  }

  goToNextStepFromWorkExperience(): void {
    if (this.newRegistrationFormWorkExperience.disabled &&
      !this.tempnewRegistrationWorkExperience &&
      !this.tempEditnewRegistrationWorkExperience) {
      this.cdkStepper.next();
    }
  }

  editItemOfNewRegistrationWorkExperience<T>(form: any, arr: Array<NewRegistrationWorkExperience>, index: number): void {
    if (index > -1) {
      arr[index].edit = true;
      this.newRegistrationFormWorkExperience.enable();
      form.reset();
      this.tempEditnewRegistrationWorkExperience = true;
      const editNewWorkExperience = !form.controls.edit.value;
      form.controls.edit.setValue(editNewWorkExperience);
      form.controls.position.setValue(arr[index].position);
      form.controls.employer.setValue(arr[index].employer);
      form.controls.city.setValue(arr[index].city);
      form.controls.startDate.setValue(arr[index].startDate);
      form.controls.current.setValue(arr[index].current);
      form.controls.endDate.setValue(arr[index].endDate);
      form.controls.country.setValue(arr[index].country);
    }
  }

  closeEditItemOfNewRegistrationWorkExperience(form: any, arr: Array<NewRegistrationWorkExperience>, index: number): void {
    arr[index].edit = false;
      this.newRegistrationFormWorkExperience.disable();
      this.tempEditnewRegistrationWorkExperience = false;
  }

  saveEditItemOfNewRegistrationWorkExperience(form: any, arr: Array<NewRegistrationWorkExperience>, index: number): void {
    if (index > -1) {
      arr[index].edit = false;
      arr[index].position = form.controls.position.value;
      arr[index].employer = form.controls.employer.value;
      arr[index].city = form.controls.city.value;
      arr[index].startDate = new Date(form.controls.startDate.value);
      arr[index].current = form.controls.current.value;
      arr[index].endDate = new Date(form.controls.endDate.value);
      arr[index].country = form.controls.country.value;
      form.controls.edit.setValue(false);
      this.tempEditnewRegistrationWorkExperience = false;
      this.newRegistrationFormWorkExperience.disable();
    }
  }

  // TODO Skills

  buildRegistarionFormSkills(): void {
    this.newRegistrationFormSkills = this.formBuilder.group({
      addNewSkill: new FormControl(false),
      skillName: new FormControl('', Validators.required),
      level: new FormControl('', Validators.required),
      edit: new FormControl(false),
    });
    this.filteredSkills = this.newRegistrationFormSkills.get('skillName')?.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterSkill(value)),
      );
    this.newRegistrationFormSkills.disable();
  }

  private _filterSkill(value: string): string[] {
    let filterValue = '';
    if (value) {
      filterValue = value.toLowerCase();
    }
    // tslint:disable-next-line:no-non-null-assertion
    return this.allCVOptionsFromDB.skills!.filter(option => option.toLowerCase().includes(filterValue));
  }

  createNewRegistrationSkill(form: any): void {
    if (!this.tempEditnewRegistrationSkills) {
      this.newRegistrationFormSkills.enable();
      form.reset();
      this.tempnewRegistrationSkills = true;
      const addNewSkill = !this.newRegistrationFormSkills.controls.addNewSkill.value;
      this.newRegistrationFormSkills.controls.addNewSkill.setValue(addNewSkill);
    }
  }

  addExistingSkill(skill: NewRegistrationSkills): void {
    const newSkill = new NewRegistrationSkills();
    newSkill.id = skill.id;
    newSkill.skillName = skill.skillName;
    newSkill.level = skill.level;
    newSkill.edit = false;
    newSkill.personalDataID = skill.personalDataID;
    this.newRegistrationSkills.push(newSkill);
  }

  saveNewSkill(form: any): void {
    this.newRegistrationFormSkills.markAllAsTouched();
    if(!this.newRegistrationFormSkills.valid){
      return
    }
    const newSkill = new NewRegistrationSkills();
    newSkill.skillName = form.controls.skillName.value;
    newSkill.level = form.controls.level.value;
    newSkill.edit = false;
    this.newRegistrationSkills.push(newSkill);
    this.newRegistrationFormSkills.controls.addNewSkill.setValue(false);
    this.newRegistrationFormSkills.disable();
    this.tempnewRegistrationSkills = false;
  }

  closeCreateNewRegistrationSkill(form: any): void {
    this.tempnewRegistrationSkills = false;
    const addNewSkill = !this.newRegistrationFormSkills.controls.addNewSkill.value;
    this.newRegistrationFormSkills.controls.addNewSkill.setValue(addNewSkill);

    form.reset();
    // tslint:disable-next-line:forin
    for (const name in form.controls) {
      form.controls[name].setErrors(null);
    }
    this.newRegistrationFormSkills.disable();
  }

  goToNextStepFromSkills(): void {
    if (this.newRegistrationSkills.length == 0){
      this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
        this.cancel = resp;
      });
      this.translate.get('stepperForm.atLeastOneSkill', { value: '' }).subscribe((resp: string) => {
        this.msg = resp;
      });
      this.snackBar.open(this.msg, this.cancel, {
        duration: 3000,
        panelClass: ['snackbar-formater']
      });
      return
    }
    if (this.newRegistrationFormSkills.disabled &&
      !this.tempnewRegistrationSkills &&
      !this.tempEditnewRegistrationSkills) {
      this.cdkStepper.next();
    }
  }

  editItemOfNewRegistrationSkill<T>(form: any, arr: Array<NewRegistrationSkills>, index: number): void {
    if (index > -1) {
      arr[index].edit = true;
      this.newRegistrationFormSkills.enable();
      form.reset();
      this.tempEditnewRegistrationSkills = true;
      const editNewSkill = !form.controls.edit.value;
      form.controls.edit.setValue(editNewSkill);
      form.controls.skillName.setValue(arr[index].skillName);
      form.controls.level.setValue(arr[index].level);
    }
  }

  closeEditItemOfNewRegistrationSkill(form: any, arr: Array<NewRegistrationSkills>, index: number): void {
    arr[index].edit = false;
    this.tempEditnewRegistrationSkills = false;
    this.newRegistrationFormSkills.disable();
  }

  saveEditItemOfNewRegistrationSkill(form: any, arr: Array<NewRegistrationSkills>, index: number): void {
    if (index > -1) {
      arr[index].edit = false;
      arr[index].skillName = form.controls.skillName.value;
      arr[index].level = form.controls.level.value;
      form.controls.edit.setValue(false);
      this.tempEditnewRegistrationSkills = false;
      this.newRegistrationFormSkills.disable();
    }
  }

  fromUnixToDate(unixDate: number, full = false): string {
    const myDate = new Date(unixDate * 1000);
    if (full) {
      return myDate.toString();
    } else {
      const correctMonth = (myDate.getMonth() + 1) < 10 ? '0' + (myDate.getMonth() + 1)
        : (myDate.getMonth() + 1);
      const correctDay = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
      return myDate.getFullYear() + '-' + correctMonth + '-' + correctDay;
    }
  }

  allFormValid(): boolean {
    if (this.newRegistrationFormPersonalInfo.valid &&
        this.newRegistrationFormHighSchool.disabled &&
        this.newRegistrationFormUniversity.disabled &&
        this.newRegistrationFormProject.disabled &&
        this.newRegistrationLanguages.length > 0 &&
        this.newRegistrationFormLanguages.disabled &&
        this.newRegistrationFormWorkExperience.disabled &&
        this.newRegistrationSkills.length > 0 &&
        this.newRegistrationFormSkills.disabled) {
        return true;
    } else { return false; }
  }

  removeItem<T>(arr: Array<T>, index: number, edit?: boolean): Array<T> {
    if (index > -1) {
      arr.splice(index, 1);
    }
    if (edit) {
      this.tempEditnewRegistrationHighSchool = false;
    }
    return arr;
  }

  sendToAPI(): void {
    if(!this.allFormValid()){
      this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
        this.cancel = resp;
      });
      this.translate.get('stepperForm.wholeValidErr', { value: '' }).subscribe((resp: string) => {
        this.msg = resp;
      });
      this.snackBar.open(this.msg, this.cancel, {
        duration: 3000,
        panelClass: ['snackbar-formater']
      });
      return
    }
    const finallForm = new FinalLongCvModel();
    finallForm.id = this.cv !== null ? this.cv.id : null;
    finallForm.gender = this.newRegistrationPersonalInfo.gender;
    finallForm.title = this.newRegistrationPersonalInfo.title;
    finallForm.titleAfterName = this.newRegistrationPersonalInfo.titleAfterName;
    finallForm.birthDate = new Date(this.newRegistrationFormPersonalInfo.controls.birthDate.value);
    finallForm.telephone = this.newRegistrationPersonalInfo.telephone;
    finallForm.streetName = this.newRegistrationPersonalInfo.streetName;
    finallForm.streetNumber = Number(this.newRegistrationPersonalInfo.streetNumber);
    finallForm.country = this.newRegistrationPersonalInfo.country;
    finallForm.city = this.newRegistrationPersonalInfo.city;
    finallForm.zip = this.newRegistrationPersonalInfo.zip;
    finallForm.highSchoolEducations = this.newRegistrationHighSchool;
    finallForm.universityEducations = this.newRegistrationUniversity;
    finallForm.projects = this.newRegistrationProject;
    finallForm.languages = this.newRegistrationLanguages;
    finallForm.workExperiences = this.newRegistrationWorkExperience;
    finallForm.skills = this.newRegistrationSkills;
    finallForm.candidateAttachment = this.newRegistrationCandidateAttachments;
    this.finallFormData = finallForm;


    this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
      this.cancel = resp;
    });

    this.stepperService.sendLongCVData(this.finallFormData).subscribe(res => {
      localStorage.setItem('user', JSON.stringify(res.body));
      this.translate.get('dashboard.jobOfferDetail.attachments.uploadSuccess').subscribe(res => {
        this.translate.get('stepperForm.updatedSuccessfully', { value: '' }).subscribe((resp: string) => {
          this.msg = resp;
        });
        this.snackBar.open(this.msg, this.cancel, {
          duration: 3000,
          panelClass: ['snackbar-formater']
        });
      })
    }, error => {
      this.translate.get('stepperForm.updatedError', { value: '' }).subscribe((resp: string) => {
        this.msg = resp;
      });
      this.snackBar.open(this.msg, this.cancel, {
        duration: 3000,
        panelClass: ['snackbar-formater']
      });
    }
    );
  }

  // Upload file to S3 logics
  addedNewFileToUpload(event: any, form: any): void {
    this.toFile = event.target.files;
    const fileToAWS = this.toFile.item(0);
    fileToAWS.arrayBuffer().then((arrayBuffer: any) => {
      const blob = new Blob([new Uint8Array(arrayBuffer)], { type: fileToAWS.type });
      this.sendUploadedFileToS3(blob, form, fileToAWS);
    });
  }

  sendUploadedFileToS3(blob: Blob, form: any, file: File): void {
    this.attachmentService.sendCandidateAttachment(blob, file.type, file.name).subscribe(res => {
      const attachment = res.body as Attachment;
      form.controls.attachmentID.setValue(attachment.id)
    }, error => {
      console.log(error);
    }
    );
  }

  downloadAttachment(form: FormGroup){
    this.attachmentService.downloadCandidateAttachment(form.get('attachmentID').value).subscribe(
      (response: Response) => {
        this.attachmentService.downloadResponseFile(response)
      },
      error => {
        this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
          this.cancel = resp;
        });
        this.translate.get('stepperForm.downloadFileErr', { value: '' }).subscribe((resp: string) => {
          this.msg = resp;
        });
        this.snackBar.open(this.msg, this.cancel, {
          duration: 3000,
          panelClass: ['snackbar-formater']
        });
      }
    )
  }

  editStepFromSummary(stepLable: string): void {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.cdkStepper.steps.length; i++) {
      const element = this.cdkStepper.steps.get(i);
      if (stepLable === element?.label) {
        this.cdkStepper.selectedIndex = i;
      }
    }
  }
}
